<template>
  <div>
    <dashboard-page-title :showAddBtn="false">الطلبات</dashboard-page-title>
    <main-table
      :baseURL="SFZ_API_LINK"
      :fields="fields"
      list_url="admin/orders"
    ></main-table>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import { SFZ_API_LINK } from "@/config/constant";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      SFZ_API_LINK,
      fields: [
        { label: "Id", key: "id", class: "text-right", sortable: true },
        { label: "اسم العميل", key: "customer.name", class: "text-right" },
        {
          label: "المنتج",
          key: "order_products",
          array_keys: ["name"],
          class: "text-right",
          type: "array",
        },
        {
          label: "العنوان",
          key: "customer_address.address",
          class: "text-right",
        },
        {
          label: "رقم الهاتف",
          key: "customer_address.phone",
          class: "text-right",
        },
        { label: "الحالة", key: "status", type: "status", class: "text-right" },
        { label: "المبلغ الاجمالى", key: "total_amount", class: "text-right" },
        {
          label: "تاريخ الانشاء",
          key: "created_at",
          class: "text-right",
          type: "date",
        },
        {
          label: "التحكم",
          key: "actions",
          class: "text-right",
          type: "actions",
          actions: [
            {
              icon: "ri-eye-line ",
              color: "primary-light",
              text: "عرض",
              ifNavigate: true,
              routeParams: ["id"],
              routeName: "viewOrder",
            },
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>
